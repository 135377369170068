<template>
  <div class="baikal-tabs baikal-tabs--partners">
    <label class="baikal-tab">
      <input type="radio" name="region" :value="1" v-model="activeTabVal" />
      <span class="checkmark">Для гостиниц</span>
    </label>

    <label class="baikal-tab">
      <input type="radio" name="region" :value="2" v-model="activeTabVal" />
      <span class="checkmark">Для ресторанов</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "PartnersTabs",
  props: {
    activeTab: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    activeTabVal: {
      get() {
        return this.activeTab;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
