<template>
  <section class="partners-section">
    <h2 class="partners-section__title">Как подключиться?</h2>
    <div class="page-top__row page-top__row--mt100">
      <div class="page-top__col"></div>
      <div class="page-top__col">
        <p class="partners-section__text mt-0">
          Всего несколько простых шагов - и для вас откроются все возможности GO
          AND GUIDE 360!
        </p>
      </div>
    </div>
    <div class="partners-steps">
      <div class="partners-step partners-step--1">
        <div class="partners-step__icon partners-step__icon--1"></div>
        <div class="partners-step__descr">
          <div class="partners-step__title">Шаг 1</div>
          <div class="partners-step__text">
            Отправить нам заявку (форма прилагается ниже)
          </div>
        </div>
      </div>

      <div class="partners-step partners-step--2">
        <div class="partners-step__icon partners-step__icon--2"></div>
        <div class="partners-step__descr partners-step__descr--2">
          <div class="partners-step__title">Шаг 2</div>
          <div class="partners-step__text">
            Получить ответ нашего специалиста
          </div>
        </div>
      </div>

      <div class="partners-step partners-step--3">
        <div class="partners-step__icon partners-step__icon--3"></div>
        <div class="partners-step__descr partners-step__descr--3">
          <div class="partners-step__title">Шаг 3</div>
          <div class="partners-step__text">
            Подобрать удобный тариф и условия комиссии за продажу услуг
          </div>
        </div>
      </div>

      <div class="partners-step partners-step--4">
        <div class="partners-step__icon partners-step__icon--4"></div>
        <div class="partners-step__descr partners-step__descr--4">
          <div class="partners-step__title">Шаг 4</div>
          <div class="partners-step__text">Оформить и подписать договор</div>
        </div>
      </div>

      <div class="partners-step partners-step--5">
        <div class="partners-step__icon partners-step__icon--5"></div>
        <div class="partners-step__descr partners-step__descr--5">
          <div class="partners-step__title">Шаг 5</div>
          <div class="partners-step__text">
            Наслаждаться эффективной работой!
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnersSteps",
};
</script>
