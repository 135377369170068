<template>
  <div class="partners">
    <Page>
      <!-- <template v-slot:breadcrumbs> breadcrumbs </template> -->
      <h1 class="page-title">Для бизнеса</h1>

      <div class="page-top__row">
        <div class="page-top__col">
          <p class="page-top__text">
            GO AND GUIDE 360 – это уникальный канал продвижения ваших товаров и
            услуг посредством размещения объектов на иммерсивной платформе,
            позволяющей показывать объекты в формате 360° - это дает возможность
            клиентам ознакомиться с интерьерами и видами различных объектов
            (гостиниц, ресторанов, торговых центров и достопримечательностей),
            буквально погружаясь в них. Эффект присутствия помогает быстро
            принять решение о покупке.
          </p>
        </div>
        <div class="page-top__col">
          <p class="page-top__text">
            При планировании маршрутов будущие туристы смогут также ознакомиться
            с культурными и природными достопримечательностями региона,
            экспозициями музеев, туристскими маршрутами, что поможет системе
            стать точкой притяжения туристов в условиях ухода из РФ крупных
            иностранных игроков.
          </p>
        </div>
      </div>

      <section class="partners-section">
        <h2 class="partners-section__title">Цели проекта</h2>
        <div class="page-top__row page-top__row--mt100">
          <div class="page-top__col">
            <p class="partners-section__text mt-0">
              Проект будет способствовать формированию цифровой туристской
              экосистемы нового уровня, которая объединит большое количество
              участников рынка в единой цифровой экосистеме с целью повышения
              доступности, качества и привлекательности туристских услуг за счет
              создания следующих сервисов:
            </p>
            <ul class="app-list app-list--partners">
              <li class="app-list__item">
                построение туристического маршрута по региону с возможностью
                бронирования гостиниц, заказа столиков и доставки еды;
              </li>
              <li class="app-list__item">
                сервис дополненной реальности (AR, augmented reality) для
                навигации по населенным пунктам и объектам показа (музеям,
                выставочным центрам, художественным галереям и другим интересным
                местам);
              </li>
              <li class="app-list__item">
                сервис дополненной реальности при создании AR-меню для
                ресторанов, кафе;
              </li>
              <li class="app-list__item">
                сервис привлечения самозанятых лиц в туристскую деятельность
                (гиды, экскурсоводы);
              </li>
              <li class="app-list__item">
                сервис аудио гидов по маршрутам или объектам
              </li>
            </ul>
          </div>
          <div class="page-top__col">
            <p class="partners-section__text">
              Данные сервисы, в свою очередь, станут сильными факторами для
              повышения привлекательности внутреннего туризма и маржинальности
              туристической отрасли в регионе.
            </p>
          </div>
        </div>
      </section>

      <PartnersTariffs />

      <PartnersSteps />

      <section v-if="false" class="partners-advantage">
        <div class="partners-advantage__list">
          <div class="partners-advantage__item">
            <div
              class="partners-advantage__icon partners-advantage__icon--map"
            ></div>
            <div class="partners-advantage__text">
              Первое преимущество для партнеров
            </div>
          </div>

          <div class="partners-advantage__item">
            <div
              class="partners-advantage__icon partners-advantage__icon--hotels"
            ></div>
            <div class="partners-advantage__text">
              Второе преимущество для партнеров
            </div>
          </div>

          <div class="partners-advantage__item">
            <div
              class="partners-advantage__icon partners-advantage__icon--earth"
            ></div>
            <div class="partners-advantage__text">
              Третье преимущество для партнеров
            </div>
          </div>
        </div>
      </section>

      <PartnersStats />

      <PartnersForm />
    </Page>
  </div>
</template>

<script>
import Page from "@/components/base/Page.vue";

import PartnersTariffs from "@/components/partials/PartnersTariffs.vue";
import PartnersSteps from "@/components/partials/PartnersSteps.vue";
import PartnersStats from "@/components/partials/PartnersStats.vue";
import PartnersForm from "@/components/partials/PartnersForm.vue";

export default {
  name: "Partners",
  components: {
    Page,
    PartnersTariffs,
    PartnersSteps,
    PartnersStats,
    PartnersForm,
  },
};
</script>
