<template>
  <div class="partners-tariffs">
    <div v-for="tariff in tariffs" :key="tariff.id" class="partners-tariff">
      <div class="partners-tariff__content">
        <h3 class="partners-tariff__title">{{ tariff.title }}</h3>
        <ul class="partners-tariff__list">
          <li
            v-for="(item, i) in tariff.items"
            :key="i"
            class="partners-tariff__list-item"
          >
            {{ item }}
          </li>
        </ul>
        <div class="partners-tariff__btn-wrap">
          <button
            type="button"
            class="btn partners-tariff__btn"
            @click="scrollToForm"
          >
            Подключиться
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import { ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "PartnersTariff",
  props: {
    tariffs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scrollToForm() {
      const el = document.querySelector("#partners-contacts");
      gsap.to(window, { duration: 1, scrollTo: { y: el, offsetY: 50 } });
    },
  },
};
</script>
