<template>
  <section class="partners-section">
    <h2 class="partners-section__title">Тарифы</h2>

    <PartnersTabs @input="setActiveCat" />

    <PartnersTariff :tariffs="activeTariffs" />
  </section>
</template>

<script>
import PartnersTabs from "@/components/partials/PartnersTabs.vue";
import PartnersTariff from "@/components/partials/PartnersTariff.vue";

export default {
  name: "PartnersTariffs",
  components: { PartnersTabs, PartnersTariff },
  data() {
    return {
      activeCategoryId: 1,
      tariffs: [
        {
          id: 1,
          categoryId: 1,
          title: "Базовый",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
          ],
        },
        {
          id: 2,
          categoryId: 1,
          title: "Расширенный",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
            "Интеграция с системой бронирования номеров.",
          ],
        },
        {
          id: 3,
          categoryId: 1,
          title: "Продвинутый",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
            "Интеграция с системой бронирования номеров.",
            "Аренда готового AR-персонажа или создание персонального AR-помощника для вашего заведения.",
          ],
        },

        {
          id: 4,
          categoryId: 2,
          title: "Базовый",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
          ],
        },
        {
          id: 5,
          categoryId: 2,
          title: "Расширенный",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
            "Интеграция с системой резервирования столиков.",
            "Возможность заказа с доставкой.",
          ],
        },
        {
          id: 6,
          categoryId: 2,
          title: "Продвинутый",
          items: [
            "Съемка вашего объекта в формате 360°.",
            "Создание виртуальной копии вашего объекта (обработка и монтаж фото, формирование тура 360 на платформе).",
            "Личный кабинет с инструментами управления внутренним контентом и аналитикой посещаемости.",
            "Интеграция с системой резервирования столиков.",
            "Возможность заказа с доставкой.",
            "Создание AR-меню (виртуальная копия фирменных блюд с указанием питательной и энергетической ценности).",
          ],
        },
      ],
    };
  },
  computed: {
    activeTariffs() {
      return this.tariffs.filter((tariff) => {
        return tariff.categoryId === this.activeCategoryId;
      });
    },
  },
  methods: {
    setActiveCat(val) {
      this.activeCategoryId = val;
    },
  },
};
</script>
