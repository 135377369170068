<template>
  <section class="partners-section">
    <h2 class="partners-section__title">Развитие проекта</h2>
    <div class="page-top__row page-top__row--mt100">
      <div class="page-top__col">
        <p class="partners-section__text mt-0">
          Мобильное приложение GO AND GUIDE 360 постоянно развивается. В ближайших
          планах компании:
        </p>
      </div>
    </div>

    <div class="partners-stats">
      <div class="partners-stats__item">
        <!-- <div class="partners-stats__num">500</div> -->
        <div class="partners-stats__text">
          Добавление новых объектов (освоение новых туристически привлекательных
          территорий)
        </div>
      </div>

      <div class="partners-stats__item">
        <!-- <div class="partners-stats__num">5010</div> -->
        <div class="partners-stats__text">
          Реконструкция исторических и архитектурных памятников местности с
          помощью инструментов дополненной реальности
        </div>
      </div>

      <div class="partners-stats__item">
        <!-- <div class="partners-stats__num">340</div> -->
        <div class="partners-stats__text">
          Создание AR-помощников – отрисованных героев, основная задача которых
          – сопровождение и помощь пользователям приложения на пути следования
          по выбранному маршруту
        </div>
      </div>

      <div class="partners-stats__item">
        <!-- <div class="partners-stats__num">34034</div> -->
        <div class="partners-stats__text">
          Создание AR-меню и 3D-моделей блюд для ресторанов, что позволит
          пользователям получать реалистичное представление о внешнем виде
          блюда, размере порций, составе и калорийности
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PartnersStats",
};
</script>
