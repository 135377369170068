<template>
  <section id="partners-contacts" class="partners-section partners-contacts">
    <h2 class="partners-section__title">Присоединиться к GO AND GUIDE 360</h2>

    <div class="partners-contacts__form">
      <ContactsForm className="contacts-form--partners" sourcePage="partners" />
    </div>

    <div class="partners-contacts__files">
      <a
        :href="require('@/assets/files/contract-photo.pdf')"
        target="_blank"
        class="file-link"
      >
        Договор на съемку 360
      </a>
      <a
        :href="require('@/assets/files/contract-rent.pdf')"
        target="_blank"
        class="file-link"
      >
        Договор размещения материалов
      </a>
    </div>
  </section>
</template>

<script>
import ContactsForm from "@/components/partials/ContactsForm.vue";

export default {
  name: "PartnersForm",
  components: {
    ContactsForm,
  },
};
</script>
